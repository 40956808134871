<template>
  <div class="detailsPop" v-if="popInfo.show">
    <div class="detail">
      <div class="colbac">
        <div class="cupout"></div>
        <div class="home-content content">
          <div class="img-detail">
            <div class="img-l">
              <div class="img">
                <img :src="imgInfo.preview" :alt="imgInfo.name" @click.stop="preview(imgInfo.preview)" />
              </div>
            </div>
            <div class="img-r" v-if="imgInfo.editor_id" v-cloak>
              <div class="top">
                <div class="type">
                  <div class="active" @click="downFn('original')" stats-mark="图片下载原图">
                    下载原图
                  </div>
                </div>
                <div class="yangt" @click="downFn('demo')" stats-mark="图片下载样图">下载样图</div>
              </div>
              <section class="collbtn">
                <el-tooltip effect="dark" v-if="!imgInfo.is_favorite" content="收藏" placement="top"
                  popper-class="card-tooltip">
                  <div class="cardiconbox iconbox-preview" @click.stop="addCollect">
                    <span class="collection">
                      <img src="@/assets/image/details_collect.png" alt="" />
                    </span>
                  </div>
                </el-tooltip>
                <el-tooltip effect="dark" v-else content="取消收藏" placement="top" popper-class="card-tooltip">
                  <div class="cardiconbox iconbox-preview" @click.stop="delCollect">
                    <span class="collection yse">
                      <img src="@/assets/image/details_collect.png" alt="" />
                    </span>
                  </div>
                </el-tooltip>
              </section>
              <h1 class="h1">{{ imgInfo.name }}</h1>
              <div class="line-body">
                <div class="h1-line"></div>
              </div>

              <ul class="info">
                <li>
                  图片ID：<span>{{ imgInfo.editor_id }}</span>
                </li>
                <li>
                  最大尺寸：<span>{{ imgInfo.original_size }}（{{ imgInfo.dpi }}dpi）</span>
                </li>
                <li>
                  图片格式：<span>{{ imgInfo.original_format }}</span>
                </li>
                <li>
                  存储大小：<span>{{
                      imgInfo.original_byte | fmtSize
                  }}</span>
                </li>
                <li>
                  作者：<span>{{ imgInfo.signature }}</span>
                </li>
              </ul>

              <div class="explain">
                <div class="bg-box">
                  <ul>
                    <li>
                      <span>授权范围：</span>
                      <span class="col">可做商用</span>
                    </li>
                    <li>
                      <span>素材授权方式：</span>
                      <span class="col">RF</span>
                    </li>
                    <li>
                      <span>第三方权利说明：</span>
                      <span class="col">不涉及肖像权或物权授权</span>
                    </li>
                    <li>
                      <span>客服电话：</span>
                      <span class="col2">400-900-0611</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bgff">
        <div class="home-content content">
          <div class="keyword">
            <p>关键词</p>
            <ul>
              <li v-for="(item, index) in keywordList" :key="index">
                <router-link @click.native="isClickWeyword" :to="{
                  name: 'SearchPage',
                  query: { keyword: item },
                }" target="_blank">{{ item }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <collect-dialog v-if="collect.visible" :moveIds="collect.moveIds" :moveFold="false" :category="1"
        @close="closeCollect" />
      <Footer v-cloak />
    </div>
  </div>
</template>

<script>
import CollectDialog from "@/components/collectDialog";
import Footer from "@/components/foot.vue";
import { ImgApi } from "@/config/axios/api";
import { mapState } from "vuex";
export default {
  name: "Detail",
  props: ["popInfo", "closeBtn"],
  components: {
    CollectDialog,
    Footer,
  },
  data () {
    return {
      imgId: "",
      imgInfo: {},
      keywordList: [],
      collect: {
        visible: false,
        moveIds: "",
        license_scope: "", //选择的授权类型
      },
      pageScroll: false,
      headerOpacity: true,
      close: true,
    };
  },

  created () {
    this.headerOpacity = true;
    this.imgId = this.popInfo.id;
    if (this.closeBtn === "close") {
      this.close = false;
    }
    this.getDetail();
  },
  computed: {
    ...mapState(['loginInfo']),
  },
  mounted () {
    this.Statistics.defalutEvent({
      statsMark: "图片详情页曝光",
      projectPage: '61',
      projectArea: "137",
      eventArea: '137',
      eventType: '1',
      eventId: '1278',
      eventName: 'img_detail_exposure',
    })
  },
  methods: {
    isClickWeyword () {
      localStorage.setItem(
        "isKeyword",
        JSON.stringify({
          is_keyword: true,
        })
      );
    },
    getDetail () {
      ImgApi.getInfo({
        id: this.imgId
      }).then((res) => {
        if (res.status == 1) {
          this.imgInfo = res.data;
          this.staticFunc.changeKandD(
            `${this.imgInfo.keywords}_${this.imgInfo.original_format}_${this.imgInfo.original_size}_${this.imgInfo.editor_id}`,
            `${this.imgInfo.name}_${this.imgInfo.keywords}_${this.imgInfo.original_format}_${this.imgInfo.original_size}_${this.imgInfo.editor_id}`
          );
          document.title = `${this.imgInfo.name}_免费下载_${this.imgInfo.original_format}_${this.imgInfo.original_size}_${this.imgInfo.editor_id}_365版权图库`;
          this.collect.moveIds = res.data.editor_id;
          this.keywordList = res.data.keywords;
        }
      });
    },
    preview (url) {
      let div = document.createElement("div");
      div.className = "img-preview";
      div.innerHTML =
        '<div style="z-index:999"><img src="' +
        url +
        '" alt=""><div onclick="document.querySelector(\'.img-preview\').remove()"><span class="iconfont icon-close"></span></div></div>';
      document.body.appendChild(div);
    },
    // 添加收藏
    addCollect () {
      if (!this.staticFunc.isLogin()) {
        this.$store.commit("watchShowLogin", true);
        return;
      }
      this.$route.meta.index = 0;
      this.collect.visible = true;
    },
    // 取消收藏
    delCollect () {
      this.$bus.$emit('delCollect', this.imgInfo);
    },
    // 关闭收藏   refresh//是否刷新数据
    closeCollect (refresh) {
      if (refresh) {
        this.getDetail();
      }
      this.collect.visible = false;
    },
    // 下载样图/原图
    downFn (type) {
      this.$bus.$emit('downloaddemo', this.imgInfo, type)
    },
  },
};
</script>

<style scoped lang="scss">
.detailsPop {
  & ::v-deep .detail-box {
    margin: 0 !important;
    padding-top: 56px;
    width: 100%;
    height: 100%;
    min-width: 1200px;
    background: none;

    .el-dialog__body {
      padding: 0;
      box-sizing: border-box;
      height: 100%;
      overflow: hidden;
      background: none;

      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }

    .el-dialog__header {
      padding: 0;

      .el-dialog__headerbtn {
        top: 84px;
        right: 46px;
        z-index: 1;

        i {
          font-size: 26px;
          color: #757580;
        }
      }
    }
  }
}

.el-icon-close {
  position: fixed;
  right: 21px;
  top: 135px;
  cursor: pointer;
  font-size: 26px;
  padding: 10px;
  color: #757580;
}

.content {
  /* @include wh(1200px,auto); */
  width: 1058px;
  margin: 24px auto;
}

.bgff {
  overflow: hidden;
  background: #fff;

  .content {
    width: auto;
    padding: 0 110px;
    margin: 0 auto 24px;
    overflow: hidden;

    .keyword {
      margin-top: 26px;
    }
  }
}

.detail {

  /* background: #f7f9f9; */
  /*  opacity: 0.92; */
  .bacm {
    // background: $activeColor;
    cursor: pointer;
    width: 100%;
    height: 775px;
    position: absolute;
    z-index: -1;

    img {
      display: block;
      width: 96%;
      height: 100%;
      margin: 0 auto;
    }
  }

  .colbac {
    background: rgba(247, 249, 249, 0.95);
    width: 100%;
    padding: 1px 0;
    position: relative;

    .cupout {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      /* cursor: zoom-out; */
    }
  }

  .img-detail {
    @include flexble;
    justify-content: space-between;
    background: linear-gradient(90deg, #ffffff 0%, #f8f9fa 100%);
    border-radius: 24px;

    .img-l {
      width: 658px;
      height: 730px;
      position: relative;

      .img {
        @include wh(100%, 100%);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        .el-image,
        img {
          width: 100%;
          object-fit: contain;
          max-height: 100%;
          cursor: zoom-in;
        }
      }

      .cardiconbox {
        position: absolute;
        right: 18px;
        top: 35px;
        cursor: pointer;
      }

      .collbtn {
        position: absolute;
        top: 18px;
        right: 20px;

        .cardiconbox {
          @include wh(32px, 32px);
          background: #ffffff;
          border-radius: 50%;
          margin-left: 14px;
          float: left;
          cursor: pointer;

          img {
            margin: 8px;
            @include wh(16px, 16px);
          }
        }
      }
    }

    .img-r {
      @include wh(400px, auto);
      background: #ffffff;
      /* box-shadow: 0px 0px 10px 0px #F4F4F4; */
      border-radius: 24px;
      padding: 32px 13px;
      box-sizing: border-box;
      position: relative;

      .collbtn {
        position: absolute;
        right: 30px;
        top: 32px;

        .cardiconbox {
          width: 40px;
          height: 40px;

          .collection {
            width: 40px;
            height: 40px;
            background: #edf2f5;
            cursor: pointer;
            border-radius: 8px;
            position: relative;
            overflow: hidden;
            display: block;

            img {
              display: block;
              width: 16px;
              height: 16px;
              margin: 12px;
            }
          }

          .yse {
            background: #ff5967;
          }
        }
      }

      .tip {
        position: absolute;
        width: 60px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #e8e8e9;
        top: 32px;
        left: 30px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0a051b;
        line-height: 40px;
        text-align: center;
      }

      .title {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0a051b;
        line-height: 28px;
        margin-bottom: 22px;
        @include text-ellipsis2(2);
        margin-top: 112px;
      }

      .line {
        background: #e8e8e9;
        height: 1px;
        width: 100%;
        margin-bottom: 26px;
      }

      .info {
        padding: 0 18px 18px;
        margin-top: 17px;
        clear: both;

        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-bottom: 10px;

          span {
            color: #0a051b;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .top {
        padding: 0 18px;
        overflow: hidden;
      }

      .type {
        width: 180px;
        float: left;

        div {
          width: 336px;
          height: 40px;
          border-radius: 8px;
          border: 1px solid #e8e8e9;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #0a051b;
          cursor: pointer;
        }

        .active {
          width: 100%;
          height: 40px;
          border-radius: 8px;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          background: $activeColor;
          border: 1px solid $activeColor;
          color: #fff;
          cursor: pointer;
        }
      }

      .yangt {
        float: left;
        margin-left: 12px;
        width: 96px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #e8e8e9;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
      }

      .explain {
        margin-top: 3px;

        .bg-box {
          padding: 18px 18px 0 18px;
          background: #f7f9fa;
          border-radius: 10px;
          overflow: hidden;
        }

        .topay-box {
          width: 336px;
          height: 46px;
          background: #edf2f5;
          background: url("../../assets/image/common/vip_bg.png");
          background-size: 100%;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          padding: 0 9px;
          margin-bottom: 14px;

          span {
            line-height: 46px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #4f260a;

            i {
              margin-right: 4px;
              vertical-align: -2px;
            }

            .icon-warning {
              color: #ff0000;
            }
          }

          .pay-btn {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0a051b;
            cursor: pointer;
          }
        }

        .dz-box {
          background: url("../../assets/image/common/danz_bg.png");
          background-size: 100% 100%;
          margin-top: 18px;
          margin: 18px auto;
        }

        ul {
          margin-bottom: 8px;

          li {
            line-height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-bottom: 10px;

            .col {
              color: #ff5967;
            }

            .col2 {
              color: #0a051b;
            }
          }
        }

        .shuom {
          margin-top: 20px;
          padding: 0 18px;

          p {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000;
            line-height: 17px;
            margin-bottom: 8px;
          }
        }

        .solaby-box {
          padding: 18px 18px 0 18px;
        }

        .solaby {
          height: 30px;
          margin-bottom: 11px;

          span {
            display: block;
            float: left;
            width: 196px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0a051b;
            line-height: 30px;
          }

          .sel-box {
            width: 72px;
            height: 30px;
            background: #f7f9fa;
            border-radius: 8px;
            float: right;
            cursor: pointer;

            span {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #0a051b;
              display: block;
              width: 100%;
              height: 100%;
              line-height: 30px;
              text-align: center;

              .sel-box {
                margin-left: 3px;
                font-size: 12px;
              }
            }

            .sel-con {
              display: none;
              width: 80px;
              height: 72px;
              background: #ffffff;
              box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.04);
              border-radius: 8px;
              margin-top: 2px;
              float: right;

              ul {
                width: 72px;
                margin: 5px auto;

                li {
                  width: 72px;
                  height: 30px;
                  border-radius: 4px;
                  line-height: 30px;
                  text-align: center;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #0a051b;
                  margin-bottom: 3px;
                }

                .active {
                  background: #f7f9fa;
                }
              }
            }

            &:hover {
              .sel-con {
                display: block;
              }
            }
          }
        }

        .commercial {
          width: 336px;
          height: 44px;
          border-radius: 10px;
          margin-bottom: 10px;
          text-align: center;
          line-height: 44px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          background: url("../../assets/image/common/danz_bg.png");
          color: #4f260a;
          cursor: pointer;
          background-size: 100% 100%;
        }
      }

      .btn {
        display: none;
        overflow: hidden;
        margin-top: 4px;
        width: 336px;
        height: 100px;
        background: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(70, 70, 70, 0.1);
        border-radius: 8px;
        border: 1px solid #e9e9eb;

        div {
          @include flex(center, center);
          /* float: left;
          margin-right: 15px;
          width: 110px;
          height: 44px;
          border-radius: 4px;
          border: 2px solid #1f1f1f;
          box-sizing: border-box;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1f1f1f;
          line-height: 40px;
          transition: 0.3s; */
          width: 324px;
          height: 40px;
          margin: 5px;
          border-radius: 8px;
          cursor: pointer;
          font-weight: 400;
          color: #0a051b;

          img {
            @include wh(16px, 16px);
            margin-right: 3px;
            font-size: 0;
          }

          .ishover {
            display: none;
          }

          /* &:hover {
            background: #1f1f1f;
            color: #ffffff;
            .ishover {
              display: inline-block;
            }
            .nohover {
              display: none;
            }
          } */
          &.active {
            background: #edf2f5;
            /* background: $activeColor;
            border: none;
            color: #ffffff;
            &:hover {
              background: #6d3df9;
            } */
          }

          &.none {
            background: #ededed;
            border: none;
            color: #868686;
            cursor: no-drop;

            &:hover {
              background: #ededed;
            }
          }
        }
      }
    }

    .line {
      width: 336px;
      height: 1px;
      background: #e8e8e9;
      margin-top: 18px;
      margin-bottom: 8px;
    }

    .fanwei {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #888888;
      line-height: 17px;
      margin-left: 267px;

      .icon-wentifankuicopy {
        font-size: 12px;
        background: #f3f3f3;
      }
    }

    .downsel {
      width: 336px;
      height: 40px;
      background: $activeColor;
      border-radius: 8px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      margin-top: 16px;

      .xia_icon {
        display: inline-block;
      }

      &:hover {
        .btn {
          display: block;
        }
      }
    }
  }

  .keyword {
    margin-top: 61px;
    margin-bottom: 60px;

    p {
      margin-bottom: 15px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0a051b;
      line-height: 28px;
    }

    ul {
      @include flex(center, space-between);
      flex-wrap: wrap;

      li {
        height: 36px;
        line-height: 36px;
        background: #f7f9fa;
        border-radius: 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;

        a {
          padding: 0 13px;
          color: #888888;
          float: left;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #0a051b;
        }

        margin: 0 10px 10px 0;
        cursor: pointer;

        &:last-of-type {
          margin-right: auto;
        }
      }
    }
  }

  .similar {
    .title {
      margin-bottom: 25px;
      line-height: 30px;

      p {
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2d2d32;
      }

      a {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;

        span {
          float: right;
          margin-left: 4px;
        }
      }
    }

    .img-box {
      margin-bottom: 120px;

      div {
        width: 292px;
        height: 218px;

        img {
          @include wh(100%, 100%);
        }
      }
    }
  }
}

.back-top {
  position: fixed;
  bottom: 110px;
  right: 38px;

  &>div {
    width: 58px;
    height: 58px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(201, 201, 201, 0.5);
    border-radius: 50%;
    @include flex(center, center);
    position: relative;
    color: #2b2b2b;
    cursor: pointer;

    &.contact {
      margin-top: 18px;

      &:hover {
        .info {
          display: block;
        }
      }
    }

    .info {
      display: none;
      position: absolute;
      right: 77px;
      top: 0;
      width: 208px;
      height: 58px;
      background: #ffffff;
      box-shadow: 0px 0px 12px 0px rgba(201, 201, 201, 0.5);
      padding: 0 24px 0 17px;
      box-sizing: border-box;
      text-align: center;
      border-radius: 4px;

      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 58px;

        span {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }
      }

      .avator-icon {
        position: absolute;
        top: 18px;
        right: -21px;
        width: 0;
        height: 0;
        border-bottom: 12px solid transparent;
        border-right: 12px solid transparent;
        border-left: 12px solid #fff;
        border-top: 12px solid transparent;
      }
    }
  }
}

.h1 {
  font-size: 18px;
  font-weight: 500;
  color: #0a051b;
  line-height: 25px;
  padding: 0 18px;
  margin-top: 28px;
  word-break: break-all;
}

.line-body {
  width: 100%;
  padding: 0 18px;
  margin-top: 20px;

  .h1-line {
    height: 1px;
    background: #f0f3f4;
  }
}
</style>