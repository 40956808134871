<template>
  <div class="details">
    <ImageDetails
      v-if="popInfoImg.show"
      :popInfo="popInfoImg"
      closeBtn="close"
      ref="title"
    ></ImageDetails>
    <VideoDetails
      v-if="popInfoVideo.show"
      :popInfo="popInfoVideo"
      ref="title"
    ></VideoDetails>
  </div>
</template>
<script>
import ImageDetails from "@/pages/search/imageDetailsComponent";
import VideoDetails from "@/pages/video/videoDetailsComponent";

export default {
  name: "Details",
  components: {
    ImageDetails,
    VideoDetails,
  },
  data() {
    return {
      popInfoImg: {
        show: false,
        data: {},
      },
      popInfoVideo: {
        show: false,
        data: {},
      },
      popInfoDesign: {
        show: false,
        data: {},
      },
      popInfoMusic: {
        show: false,
        data: {},
        recom: {},
      },
      popInfoMusicOther: {
        show: false,
        data: {},
        recom: {},
      },
      popInfoExempt: {
        show: false,
        data: {},
      },
      popInfoPpt: {
        show: false,
        data: {},
      },
    };
  },
  created() {
    
    if (this.$route.query.img) {
      this.popInfoImg.id = this.$route.query.id;
      this.popInfoImg.show = true;
    }
    if (this.$route.query.video) {
      this.popInfoVideo.id = this.$route.query.id;
      this.popInfoVideo.show = true;
    }
  },
  computed: {},
  mounted() {
    setTimeout(() => {
      window.addEventListener("scroll", this.pageScrollFn);
      this.pageScrollFn();
    }, 300);
  },
  destroyed() {
    window.removeEventListener("scroll", this.pageScrollFn);
    this.pageScrollFn("destroy");
  },
  methods: {
    pageScrollFn(status) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let $domR = document.querySelector(".header-right");
      let $domC = document.querySelector(".header-center");
      if (status === "destroy" || scrollTop < 130) {
        $domR.style.display = "block";
        $domC.style.display = "block";
        this.changeHeader("destroy");
      } else {
        $domR.style.display = "none";
        $domC.style.display = "none";
        this.changeHeader();
      }
    },
    changeHeader(status) {
      this.$nextTick(() => {
        let $domI = document.getElementById("header");
        let $innerText = document.querySelector(".custom-div");
        let $eleL = document.querySelector(".music-detail");
        let $eleImgL = document.querySelector(".img-detail");
        if (status === "destroy") {
          if ($innerText) {
            $domI.removeChild($innerText);
          }
          return;
        }
        if (!$innerText) {
          let div = document.createElement("h2");
          if (this.$route.query.img) {
            div.innerHTML = this.$refs.title.imgInfo.name;
          }

          if (this.$route.query.video) {
            div.innerHTML = this.$refs.title.detailData.name;
          }

          div.className = "custom-div";
          div.style.lineHeight = "60px";
          div.style.marginLeft =
            (($eleL && $eleL.offsetLeft) || ($eleImgL && $eleImgL.offsetLeft)) -
            40 +
            "px";
          div.style.color = "#666";
          div.style.fontSize = "18px";
          div.style.width = "656px";
          div.style.textOverflow = "ellipsis";
          div.style.whiteSpace = "nowrap";
          div.style.overflow = "hidden";
          $domI.appendChild(div);
        } else {
          $innerText.style.display = "block";
        }
      });
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss">
.details {
  height: 100%;
  padding-top: 60px;
  background: #f8fafa;
}
</style>
