<template>
  <div class="detailsPop" v-if="popInfo.show">
    <div class="detail-box" v-if="detailData.id">
      <div class="content">
        <div class="clic-box"></div>
        <div class="music-detail">
          <div class="left">
            <div class="video-box">
              <video id="video" :src="detailData.demo_url" preload="metadata" class="product-video" controls="true"
                :poster="detailData.preview" controlslist="nodownload"></video>
            </div>
          </div>
          <div class="right">
            <div class="wrap">
              <div class="top">
                <div class="type">
                  <div class="active" @click="downFn" stats-mark="视频下载">下载视频</div>
                </div>
                <span @click.stop="addCollect(detailData)" v-if="!detailData.is_favorite" class="collection no">
                  <img src="@/assets/image/details_collect.png" alt="" />
                </span>
                <span v-if="detailData.is_favorite" @click.stop="delCollect(detailData)" class="collection yse">
                  <img src="@/assets/image/details_collect.png" alt="" />
                </span>
              </div>
              <h1 class="h1">{{ detailData.name }}</h1>
              <div class="line">
                <div class="h1-line"></div>
              </div>

              <ul class="ul-box">
                <li>
                  <span>视频素材ID：</span>
                  <span class="con">{{ detailData.editor_id }}</span>
                </li>
                <li>
                  <span>最大尺寸：</span>
                  <span class="con">{{ detailData.width }} × {{ detailData.height }}px ({{
                      detailData.dpi
                  }}dpi）</span>
                </li>
                <li>
                  <span>视频格式：</span>
                  <span class="con">{{ detailData.original_format }}</span>
                </li>
                <li>
                  <span>存储大小：</span>
                  <span class="con">{{ detailData.original_byte | fmtSize }}</span>
                </li>
                <li>
                  <span>作者：</span>
                  <span class="con">{{ detailData.signature }}</span>
                </li>
              </ul>

              <div class="explain">
                <div class="bg-box">
                  <ul>
                    <li>
                      <span>授权范围：</span>
                      <span class="col">可做商用</span>
                    </li>
                    <li>
                      <span>素材授权方式：</span>
                      <span class="col">RF</span>
                    </li>
                    <li>
                      <span>第三方权利说明：</span>
                      <span class="col">不涉及肖像权或物权授权</span>
                    </li>
                    <li>
                      <span>客服电话：</span>
                      <span class="col2">400-900-0611</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="keyword-box">
        <h2 class="title">关键字</h2>
        <ul>
          <li v-for="item in keywordList" :key="item" @click="keySeac(item)">
            {{ item }}
          </li>
        </ul>
      </div>

      <Footer v-cloak />
      <pay v-if="payInfo.visible" :payInfo="payInfo" @close="closePay" />
      <collect-dialog v-if="collect.visible" :moveIds="collect.moveIds" :moveFold="false" :category="2"
        @close="closeCollect" />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/foot.vue";
import { VideoApi, User } from "@/config/axios/api";
import CollectDialog from "@/components/collectDialog";
import Pay from "../../components/pay";
import { mapState } from "vuex";

export default {
  name: "videoDetailsComponents",
  components: { Footer, CollectDialog, Pay },
  props: ["popInfo"],
  data () {
    return {
      detailData: {},
      keywordList: [],
      collect: {
        visible: false,
        moveIds: 0,
      },
      payInfo: {
        visible: false,
        solaVis: false,
      },
    };
  },

  created () {
    this.getDetail();
  },
  computed: {
    ...mapState(['loginInfo']),
  },
  mounted () {
    this.Statistics.defalutEvent({
      statsMark: "视频详情页曝光",
      projectPage: '61',
      projectArea: "137",
      eventArea: '137',
      eventType: '1',
      eventId: '1279',
      eventName: 'video_detail_exposure',
    })
  },
  methods: {
    isClickWeyword () {
      localStorage.setItem(
        "isKeyword",
        JSON.stringify({
          is_keyword: true,
        })
      );
    },
    // 获取详情
    getDetail () {
      VideoApi.videoDetail({
        id: this.popInfo.id,
      }).then((res) => {
        if (res.status == 1) {
          this.detailData = res.data;
          this.staticFunc.changeKandD(
            `${this.detailData.keywords}_${this.detailData.original_format}_${this.detailData.editor_id}`,
            `${this.detailData.name}_${this.detailData.keywords}_${this.detailData.original_format}_${this.detailData.editor_id}`
          );
          document.title = `${this.detailData.name}_免费下载_${this.detailData.original_format}_${this.detailData.editor_id}_365版权图库`;
          this.collect.moveIds = res.data.editor_id;
          this.keywordList = res.data.keywords
            ? res.data.keywords
            : [];
        }
      });
    },


    keySeac (data) {
      this.isClickWeyword();
      const { href } = this.$router.resolve({
        name: "VideoSearchPage",
        query: {
          keyword: data,
        },
      });
      window.open(href, "_blank");
    },

    addCollect (item) {
      if (!this.staticFunc.isLogin()) {
        this.$store.commit("watchShowLogin", true);
        return;
      }
      this.collect.moveIds = item.editor_id;
      this.$route.meta.index = 0;
      this.collect.visible = true;
      return false;
    },

    closeCollect (refresh) {
      if (refresh) {
        this.getDetail();
      }
      this.collect.visible = false;
    },
    delCollect (item) {
      this.$bus.$emit('delVideoCollect', item);
    },

    // 下载视频
    async downFn () {
      if (!this.staticFunc.isLogin()) {
        this.$store.commit("watchShowLogin", true);
        return;
      }
      VideoApi.videoCopyrightDownload({
        id: this.detailData.editor_id,
      }).then((res) => {
        if (res.status == 1) {
          if (res.data.download_url) {
            var urls = res.data.download_url;
            this.downImg(urls, this.detailData.isheeditor_idji_id, res.message);
          }
        } else {
          if (this.loginInfo.is_sc_vip != 1 || this.loginInfo.sc_vip_level != 3) {
            this.payInfo.visible = true;
            this.payInfo.payment_channel = '下载视频';
          }
        }
      });
    },
    downImg (url, name, msg) {
      this.staticFunc.downloadImg(url, name, () => { });
      this.$message.success({ message: msg || '视频下载成功' });
    },
    closePay (refresh) {
      if (refresh) {
        this.$store.dispatch("loginInfo")
      }
      this.payInfo.visible = false;
    },
  },
  filters: {
    formatTime (time) {
      // 格式化毫秒，返回String型分秒对象
      // 有可能没获取到，为NaN
      if (!time) return "00:00";
      let min;
      let sec;
      min = Math.floor(time / 60)
        .toString()
        .padStart(2, "0");
      sec = Math.floor(time % 60)
        .toString()
        .padStart(2, "0");
      return min + ":" + sec;
    },
    formatFile (size) {
      if (!size) return "0MB";
      var m = size / 1024;
      return m.toFixed(2) + "MB";
    },
  },
};
</script>

<style scoped lang="scss">
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/deep/ .el-carousel__arrow {
  width: 48px;
  height: 48px;
  font-size: 20px;
}

.detailsPop {

  /* position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9; */
  .detail-box {
    margin: 0 !important;
    padding-top: 56px;
    width: 100%;
    height: 100%;
    min-width: 1200px;
    background: none;

    /* .el-dialog__body {
      padding: 0;
      box-sizing: border-box;
      height: 100%;
      overflow: hidden;
      background: none; */
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    .content {
      margin: 0;
      width: 100%;
      height: 748px;
      background: none;
      background: rgba(247, 249, 249, 0.95);
      //background: rgba(225, 225, 225, 0.92);
      overflow: hidden;
      position: relative;

      .clic-box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        /* cursor: zoom-out; */
      }

      .music-detail {
        width: 1058px;
        height: 700px;
        background: #ffffff;
        border-radius: 24px;
        margin: 24px auto;
        z-index: 9;
        position: absolute;
        left: 50%;
        margin-left: -529px;

        .left {
          width: 656px;
          height: 700px;
          background: linear-gradient(90deg, #ffffff 0%, #f8f9fa 100%);
          border-radius: 24px 0px 0px 24px;
          float: left;
          position: relative;

          .zhiz-box {
            position: absolute;
            right: 29px;
            top: 60px;
            width: 117px;
            height: 223px;
            transition: all 0.28s;
            transform-origin: 21px 17px;

            img {
              width: 100%;
            }
          }

          .roud {
            transform: rotate(45deg);
          }

          .video-box {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 8px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;

            .product-video {
              max-width: 100%;
              max-height: 100%;
              cursor: pointer;
            }
          }
        }

        .right {
          width: 400px;
          height: 700px;
          float: left;

          .wrap {
            width: 374px;
            margin: 32px auto;

            .top {
              overflow: hidden;
              padding: 0 18px;

              .format {
                width: 58px;
                height: 38px;
                border-radius: 8px;
                border: 1px solid #e8e8e9;
                float: left;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #0a051b;
                text-align: center;
                line-height: 38px;
              }

              .collection {
                float: right;
                width: 40px;
                height: 40px;
                cursor: pointer;
                border-radius: 8px;
                position: relative;
                overflow: hidden;

                img {
                  display: block;
                  width: 16px;
                  height: 16px;
                  margin: 12px;
                }
              }

              .yse {
                background: #ff5967;
              }

              .no {
                background: #edf2f5;
              }
            }

            .ul-box {
              overflow: hidden;
              margin-top: 17px;
              padding: 0 18px;

              li {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 20px;
                margin-bottom: 10px;

                .con {
                  color: #0a051b;
                  padding-right: 10px;
                }
              }
            }

            .type {
              width: 280px;
              float: left;

              .active {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                text-align: center;
                line-height: 40px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                background: $activeColor;
                border: 1px solid $activeColor;
                color: #fff;
                cursor: pointer;
              }
            }

            .explain {
              .bg-box {
                padding: 18px 18px 0 18px;
                background: #f7f9fa;
                border-radius: 10px;
                overflow: hidden;
              }

              .topay-box {
                width: 336px;
                height: 46px;
                background: #edf2f5;
                background: url("../../assets/image/common/vip_bg.png");
                background-size: 100% 100%;
                border-radius: 8px;
                display: flex;
                justify-content: space-between;
                padding: 0 9px;
                margin-bottom: 14px;

                span {
                  line-height: 46px;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 600;
                  color: #4f260a;

                  i {
                    margin-right: 4px;
                    vertical-align: -2px;
                  }

                  .icon-warning {
                    color: #ff0000;
                  }
                }

                .pay-btn {
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 600;
                  color: #4f260a;
                  cursor: pointer;
                }
              }

              .dz-box {
                background: url("../../assets/image/common/danz_bg.png");
                background-size: 100% 100%;
                margin-top: 18px;
                margin: 18px auto;
              }

              ul {
                margin-bottom: 8px;

                li {
                  line-height: 20px;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #999999;
                  margin-bottom: 10px;

                  .col {
                    color: #ff5967;
                  }

                  .col2 {
                    color: #0a051b;
                  }
                }
              }

              .shuom {
                margin-top: 20px;
                padding: 0 18px;

                p {
                  font-size: 12px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #000000;
                  line-height: 17px;
                  margin-bottom: 8px;
                }
              }

              .solaby-box {
                padding: 18px 18px 0 18px;
              }

              .solaby {
                height: 30px;
                margin-bottom: 11px;

                span {
                  display: block;
                  float: left;
                  width: 196px;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #0a051b;
                  line-height: 30px;
                }

                .sel-box {
                  width: 72px;
                  height: 30px;
                  background: #f7f9fa;
                  border-radius: 8px;
                  float: right;
                  cursor: pointer;

                  span {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #0a051b;
                    display: block;
                    width: 100%;
                    height: 100%;
                    line-height: 30px;
                    text-align: center;
                  }

                  .sel-con {
                    display: none;
                    width: 80px;
                    height: 72px;
                    background: #ffffff;
                    box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.04);
                    border-radius: 8px;
                    margin-top: 2px;
                    float: right;

                    ul {
                      width: 72px;
                      margin: 5px auto;

                      li {
                        width: 72px;
                        height: 30px;
                        border-radius: 4px;
                        line-height: 30px;
                        text-align: center;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #0a051b;
                        margin-bottom: 3px;
                        cursor: pointer;
                      }

                      .active {
                        background: #f7f9fa;
                      }
                    }
                  }

                  &:hover {
                    .sel-con {
                      display: block;
                    }
                  }
                }
              }

              .commercial {
                width: 336px;
                height: 44px;
                border-radius: 10px;
                margin-bottom: 10px;
                text-align: center;
                line-height: 44px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                background: url("../../assets/image/common/danz_bg.png");
                color: #4f260a;
                cursor: pointer;
                background-size: 100% 100%;
              }
            }
          }
        }
      }

      .left-btn {
        position: absolute;
        left: 22px;
        width: 48px;
        height: 48px;
        top: 50%;
        transform: rotate(180deg);
        margin-top: -24px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.28s;

        img {
          width: 100%;
        }

        .act_btn {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
        }

        &:hover {
          .act_btn {
            opacity: 1;
          }
        }
      }

      .right-btn {
        position: absolute;
        right: 22px;
        width: 48px;
        height: 48px;
        top: 50%;
        margin-top: -24px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.28s;

        img {
          width: 100%;
        }

        .act_btn {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
        }

        &:hover {
          .act_btn {
            opacity: 1;
          }
        }
      }
    }

    .keyword-box {
      width: 100%;
      background: #fff;
      min-height: 144px;
      padding: 0 110px;
      overflow: hidden;

      .title {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0a051b;
        line-height: 28px;
        margin-top: 26px;
      }

      ul {
        overflow: hidden;
        margin-top: 16px;

        li {
          display: inline-block;
          margin-right: 16px;
          padding: 8px 14px;
          background: #f7f9fa;
          border-radius: 8px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #0a051b;
          line-height: 20px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }

    /* } */
    .el-dialog__header {
      padding: 0;

      .el-dialog__headerbtn {
        top: 84px;
        right: 46px;
        z-index: 1;

        i {
          font-size: 26px;
          color: #757580;
        }
      }
    }

    .el-collapse-item__header {
      font-size: 14px;
    }
  }
}

.back-top {
  position: fixed;
  bottom: 110px;
  right: 38px;
  z-index: 9;

  &>div {
    width: 58px;
    height: 58px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(201, 201, 201, 0.5);
    border-radius: 50%;
    @include flex(center, center);
    position: relative;
    color: #2b2b2b;
    cursor: pointer;

    &.contact {
      margin-top: 18px;

      &:hover {
        .info {
          display: block;
        }
      }
    }

    .info {
      display: none;
      position: absolute;
      right: 77px;
      top: 0;
      width: 208px;
      height: 58px;
      background: #ffffff;
      box-shadow: 0px 0px 12px 0px rgba(201, 201, 201, 0.5);
      padding: 0 24px 0 17px;
      box-sizing: border-box;
      text-align: center;
      border-radius: 4px;

      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 58px;

        span {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }
      }

      .avator-icon {
        position: absolute;
        top: 18px;
        right: -21px;
        width: 0;
        height: 0;
        border-bottom: 12px solid transparent;
        border-right: 12px solid transparent;
        border-left: 12px solid #fff;
        border-top: 12px solid transparent;
      }
    }
  }
}

.rotate {
  -webkit-animation: spin 15s linear infinite;
  animation: spin 15s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
    transition: all 15s;
  }
}

.h1 {
  font-size: 18px;
  font-weight: 500;
  color: #0a051b;
  line-height: 25px;
  padding: 0 18px;
  margin-top: 28px;
  word-break: break-all;
}

.line {
  width: 100%;
  padding: 0 18px;
  margin-top: 20px;

  .h1-line {
    height: 1px;
    background: #f0f3f4;
  }
}
</style>
